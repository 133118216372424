<template>
  <div class="sign_wrap">
    <div id="canvasBox">
    </div>
    <div :class="isHorizontal?'button-box button-box-h':'button-box button-box-v'">
      <div class="text">请横向签名</div>
      <template v-if="isHorizontal">
        <el-button @click="handleClear" style="height: 90px; width: 150px; margin-right: 15px; font-size: 40px;">清 除</el-button>
        <el-button type="primary" @click="handleSign" :loading="subLoading" style="height: 90px; width: 150px; font-size: 40px;">确 定</el-button>
      </template>
      <template v-else>
        <el-button @click="handleClear" style="height: 180px; width: 300px; margin-right: 30px; font-size: 80px;">清 除</el-button>
        <el-button type="primary" @click="handleSign" :loading="subLoading" style="height: 180px; width: 300px; font-size: 80px;">确 定</el-button>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SignView',
  data () {
    return {
      canvasWidth: 0,
      canvasHeight: 0,
      canvas: null,
      ctx: null,
      listenResizeNum1: 0,
      listenResizeNum2: 0,
      isHorizontal: false,
      contractTemplateId: '',
      name: '',
      subLoading: false,
    }
  },
  mounted() {
    const query = this.$route.query
    if (query) {
      this.contractTemplateId = query.contractTemplateId
      this.name = query.name
    }
    document.body.addEventListener('touchmove', this.handleTouchmove, { passive: false })
    window.addEventListener('resize', this.listenResize);
    this.listenResize()
  },
  beforeDestroy() {
    this.canvas = null
    document.body.removeEventListener('touchmove', this.handleTouchmove)
    window.removeEventListener("resize",this.listenResize);
  },
  methods: {
    listenResize() {
      if (document.body.clientWidth < document.body.clientHeight) {
        if (this.listenResizeNum1 === 0) {
          this.listenResizeNum1++
          this.listenResizeNum2 = 0
          this.isHorizontal = false
          this.initCanvas()
        }
      } else {
        if (this.listenResizeNum2 === 0) {
          this.listenResizeNum2++
          this.listenResizeNum1 = 0
          this.isHorizontal = true
          this.initCanvas()
        }
      }
    },
    initCanvas() {
      const isTouchDevice = 'ontouchstart' in document.documentElement;
      const canvasBox = document.getElementById('canvasBox')
      
      canvasBox.innerHTML = ""

      this.canvas = document.createElement('canvas')
      this.canvasHeight = canvasBox.clientHeight
      this.canvasWidth = canvasBox.clientWidth
      this.canvas.style.cssText = `width: ${this.canvasWidth}px; height: ${this.canvasHeight}px; background-color: #ffffff;`

      this.canvas.width = this.canvasWidth
      this.canvas.height = this.canvasHeight

      canvasBox.appendChild(this.canvas)
      this.ctx = this.canvas.getContext('2d')
      this.ctx.fillStyle = 'black'
      this.ctx.lineWidth = 20
      this.ctx.lineCap = 'round'

      let last = []
      if (isTouchDevice) {
        this.canvas.ontouchstart = (e) => {
          let x = e.touches[0].clientX
          let y = e.touches[0].clientY
          last = [x, y]
        }

        this.canvas.ontouchmove = (e) => {
          let x = e.touches[0].clientX
          let y = e.touches[0].clientY
          this.drawLine(last[0], last[1], x, y)
          last = [x, y]
        }
      } else {
        let painting = false

        this.canvas.onmousedown = (e) => {
          painting = true
          last = [e.clientX, e.clientY]
        }
        this.canvas.onmousemove = (e) => {
          if (painting === true) {
            this.drawLine(last[0], last[1], e.clientX, e.clientY)
            last = [e.clientX, e.clientY]
          }
        }
        this.canvas.onmouseup = () => {
          painting = false
        }
      }
    },
    handleTouchmove(e) {
      e = e || window.event
      e.preventDefault()
    },
    drawLine(x1, y1, x2, y2) {
      this.ctx.beginPath()
      this.ctx.moveTo(x1, y1)
      this.ctx.lineTo(x2, y2)
      this.ctx.closePath()
      this.ctx.stroke()
    },
    handleClear() {
      this.ctx.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
    },
    handleSign() {
      this.subLoading = true
      try {
        const image = this.convertCanvasToImage(this.canvas)
        window.localStorage.setItem('signedImageUrl', image.src)
      } catch (error) {
        this.$message.error(error)
      }
      this.subLoading = false
      this.$router.push({
        path: '/home',
        query: {
          actionType: 'signed',
          contractTemplateId: this.contractTemplateId,
          name: this.name,
          direction: this.isHorizontal?'horizontal':'vertical'
        }
      })
    },
    convertCanvasToImage(canvas) {
      const image = new Image()
      image.src = canvas.toDataURL('image/png', 0.6)
      return image
    },
    // 生成画布
  }
}
</script>

<style lang="scss" scoped>
.sign_wrap {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px 10px;
  box-sizing: border-box;
  position: relative;

  #canvasBox {
    width: 100%;
    height: 100%;
    font-size: 0;
    text-align: left;
  }

  .button-box {
    position: absolute;
    display: flex;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    .text {
      white-space: nowrap;
      color: #fff;
    }
  }

  .button-box-h {
    height: 120px;
    width: 550px;
    justify-content: flex-end;
    padding: 15px 15px;
    bottom: 60px;
    right: 10px;
    transform-origin: left top;
    transform: rotate(0deg);

    .text {
      font-size: 30px;
      margin-right: 50px;
    }
  }

  .button-box-v {
    height: 240px;
    width: 1100px;
    justify-content: flex-end;
    padding: 30px 30px;
    top: 1110px;
    left: calc(100% - 250px);
    transform-origin: left top;
    transform: rotate(-90deg);

    .text {
      font-size: 60px;
      margin-right: 100px;
    }
  }
}
</style>
